import git from './git';
import bash from './bash';
import npm from './npm';
import reactNative from './reactNative';
import react from './react';
import homebrew from './homebrew';
import yarn from './yarn';
import gatsby from './gatsby';
import netlify from './netlify';
import awsAmplify from './awsAmplify';
import python from './python';
import django from './django';
import awsCLI from './awsCLI';
import vim from './vim';
import terraform from './terraform';

const allCommands = {
  git,
  react,
  reactNative,
  npm,
  bash,
  homebrew,
  yarn,
  gatsby,
  netlify,
  awsAmplify,
  python,
  django,
  awsCLI,
  vim,
  terraform,
};

export default allCommands;
